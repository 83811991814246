import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import Arrow from "../images/inline/arrow-right.svg"

const root = css`
  display: inline-flex;
  background-color: transparent;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
  border: none;
  letter-spacing: 0.25px;
  padding: ${props => {
    switch (props.size) {
      case "sm":
      case "md":
        return "10px 20px"
      case "lg":
        return "14px 26px"
      default: {
        return "10px 20px"
      }
    }
  }};

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    border-radius: 24px;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    background-color: ${props => {
      switch (props.variant) {
        case "primary": {
          return "#fff"
        }
        case "secondary": {
          return props.theme.colors.aqua
        }
        default: {
          return props.theme.colors.aqua
        }
      }
    }};
  }

  &:hover {
    &::before {
      transform: scale(1.05);
    }

    & > svg {
      transform: translateX(3px);
    }
  }
`

const RootBtn = styled.button`
  ${root}
`

const RootAnchor = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dkGray};
  ${root}
`

const ArrowIcon = styled(Arrow)`
  margin-left: 8px;
  transition: transform 0.2s ease-in-out;
  transform: translateX(0);
`

const Button = ({ size, variant, children, type, ...attrs }) =>
  type === "anchor" ? (
    <RootAnchor size={size} variant={variant} {...attrs}>
      {children} <ArrowIcon />
    </RootAnchor>
  ) : (
    <RootBtn size={size} variant={variant} {...attrs}>
      {children} <ArrowIcon />
    </RootBtn>
  )

Button.defaultProps = {
  size: "md",
  variant: "primary",
}

Button.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
}

export default Button
