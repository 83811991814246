export default {
  maxWidth: "1360px",
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    ltBlue: "#3ED5F6",
    dkBlue: "#2375D1",
    aqua: "#84E6DB",
    gray: "#F4F4F4",
    dkGray: "#1F2936",
    yellow: "#CAFA5A",
  },
  mixins: {
    visuallyHidden:
      "position: absolute; overflow: hidden; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; padding: 0; border: 0;",
    zeroList: "list-style: none; margin: 0; padding-left: 0",
    shadow:
      "box-shadow: 0 10px 20px rgba(0,0,0,0.2), 0 6px 6px rgba(0,0,0,0.15);",
  },
  fontSize: {
    h1: "48px",
    h2: "36px",
    h3: "24px",
    text: "16px",
    smText: "14px",
  },
  zIndex: {
    0: 0,
    1: 100,
    2: 200,
    3: 300,
    4: 400,
    5: 500,
  },
}
