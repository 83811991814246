import React, { useState, useRef } from "react"
import styled from "styled-components"
import FocusLock from "react-focus-lock"
import breakpoint from "styled-components-breakpoint"
import { useGlobalJsonForm } from "gatsby-tinacms-json"
import { useStaticQuery, Link, graphql } from "gatsby"

import { useOnClickOutside } from "../hooks/useOnClickOutside"
import settingsForm from "../../content/forms/settings"
import Hamburger from "./Hamburger"
import MobileMenu from "./MobileMenu"
import Button from "./Button"
import Logo from "../images/inline/logo.svg"

const Root = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px 20px;
  z-index: 10;
`

const AppLogo = styled(Logo)`
  display: inline-flex;
`

const Nav = styled.ul`
  display: flex;
  align-items: center;
  ${breakpoint("xs", "md")`
    display: none;
  `}
  ${props => props.theme.mixins.zeroList}
`

const NavItem = styled.li`
  margin-bottom: 0;

  &:last-child {
    margin-left: 30px;
  }

  & > a {
    color: ${props => props.theme.colors.dkGray};
    text-decoration: none;
  }
`

const Hidden = styled.span`
  ${props => props.theme.mixins.visuallyHidden}
`
const MobileNav = styled.div`
  ${breakpoint("md")`
    display: none;
  `}
`

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  const node = useRef()
  const menuId = "main-menu"
  const { dataJson } = useStaticQuery(graphql`
    query HeaderQuery {
      dataJson(fileRelativePath: { eq: "/content/data/settings.json" }) {
        email
        phone

        rawJson
        fileRelativePath
      }
    }
  `)

  useGlobalJsonForm(dataJson, settingsForm)
  useOnClickOutside(node, () => setOpen(false))

  return (
    <Root>
      <h1 style={{ margin: 0 }}>
        <Link to="/">
          <Hidden>{siteTitle}</Hidden>
          <AppLogo />
        </Link>
      </h1>
      <Nav>
        {dataJson.phone && (
          <NavItem>
            <a href={`tel:${dataJson.phone}`}>{dataJson.phone}</a>
          </NavItem>
        )}
        <NavItem>
          <Button
            variant="secondary"
            type="anchor"
            href={`mailto:${dataJson.email}?subject=Get%20In%20Touch`}
            target="_blank"
          >
            Contact Us
          </Button>
        </NavItem>
      </Nav>
      <MobileNav ref={node}>
        <FocusLock disabled={!open}>
          <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
          <MobileMenu
            email={dataJson.email}
            phone={dataJson.phone}
            open={open}
            setOpen={setOpen}
            id={menuId}
          />
        </FocusLock>
      </MobileNav>
    </Root>
  )
}

export default Header
