export default {
  label: "Settings",
  fields: [
    {
      name: "rawJson.siteTitle",
      component: "text",
      label: "Site Title",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.siteDescription",
      component: "textarea",
      label: "Site Description",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.siteUrl",
      component: "text",
      label: "Site URL",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.siteAuthor",
      component: "text",
      label: "Site Author",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.email",
      component: "text",
      label: "Contact Email",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.phone",
      component: "text",
      label: "Contact Phone Number",
      parse(value) {
        return value || ""
      },
    },
    {
      name: "rawJson.address",
      component: "textarea",
      label: "Business Address",
      parse(value) {
        return value || ""
      },
    },
  ],
}
