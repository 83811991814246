// Burger.js
import React from "react"
import styled from "styled-components"

const Root = styled.button`
  top: 20px;
  right: 20px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
    transform: scale(1.1);
  }

  div {
    width: 20px;
    height: 3px;
    background: ${({ theme }) => theme.colors.dkGray};
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`

const Burger = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false

  return (
    <Root
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <div />
      <div />
      <div />
    </Root>
  )
}

export default Burger
