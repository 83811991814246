import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

import theme from "./theme"

export default createGlobalStyle`
  ${reset}
  html {
    height: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Poppins', sans-serif;
    color: ${theme.colors.dkGray};
    background-color: ${theme.colors.gray}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins',serif;
    color: ${theme.colors.dkGray};
  }

  h1 {
    font-size: ${theme.fontSize.h2};
    font-weight: bold;

    @media (min-width: ${theme.breakpoints.lg}px) {
      font-size: ${theme.fontSize.h1};
    }
  }

  h2 {
    font-size: ${theme.fontSize.h3};
    font-weight: bold;
    margin-bottom: .7em;

    @media (min-width: ${theme.breakpoints.lg}px) {
      font-size: ${theme.fontSize.h2};
    }
  }

  h3 {
    font-size: ${theme.fontSize.subtitle};

    @media (min-width: ${theme.breakpoints.lg}px) {
      font-size: ${theme.fontSize.h3};
    }

  }

  p {
    line-height: 1.65;
    font-size: ${theme.fontSize.text};

    @media (min-width: ${theme.breakpoints.lg}px) {
      font-size: ${theme.fontSize.lgText};
    }
  }
`
