/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import theme from "../styles/theme"
import Header from "./Header"
import Footer from "./Footer"
import GlobalStyle from "../styles/GlobalStyle"
import infiniteSquare from "../images/infinite-square.svg"

const AppWrap = styled.div`
  background-color: #fff;
  max-width: 1800px;
  margin: 0 auto;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    display: block;
    background-image: url(${infiniteSquare});
    z-index: -1;
  }

  &::before {
    top: 0;
    height: 800px;
  }

  &::after {
    bottom: 0;
    height: 200px;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <AppWrap>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main id="main">{children}</main>
        <Footer />
        <GlobalStyle />
      </AppWrap>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
