import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useStaticQuery, graphql } from "gatsby"

import footerBg from "../images/footer-bg.svg"
import footerBgLine from "../images/footer-bg-line.svg"
import Logo from "../images/inline/logo-only.svg"

const Root = styled.section`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  text-align: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;

    ${breakpoint("lg")`
      background-position-x: initial;
    `}
  }

  &::before {
    bottom: 0;
    background-image: url(${footerBg});

    ${breakpoint("lg")`
       right: -35px;
    `}
  }

  &::after {
    bottom: -16px;
    right: 0;
    background-image: url(${footerBgLine});
  }
`

const Content = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: #fff;
`

const Copyright = styled.p`
  position: absolute;
  bottom: 15px;
`

const Address = styled.p`
  margin-top: 20px;
  white-space: pre-line;
`

const Footer = () => {
  const { dataJson } = useStaticQuery(graphql`
    query FooterQuery {
      dataJson(fileRelativePath: { eq: "/content/data/settings.json" }) {
        address

        rawJson
        fileRelativePath
      }
    }
  `)

  return (
    <Root>
      <Content>
        <Logo />
        <Address>{dataJson.address}</Address>
        <Copyright>Ice Age&#8482;</Copyright>
      </Content>
    </Root>
  )
}

export default Footer
