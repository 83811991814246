import React from "react"
import styled from "styled-components"

import Logo from "../images/inline/logo.svg"

const Root = styled.nav`
  display: flex;
  flex-direction: column;
  background: white;
  min-width: 300px;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 100%;

  a {
    padding: 30px 0;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s linear;
    border-bottom: 4px solid ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.dkGray};
    font-size: ${({ theme }) => theme.fontSize.h3};
    transition: 0.3s background-color ease-in-out;

    span {
      display: block;
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};

      span {
        transform: scale(1.1);
      }
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    border-top: 4px solid ${({ theme }) => theme.colors.gray};
  }
`

const AppLogo = styled.div`
  margin: 30px auto;

  svg {
    width: 100%;
    height: 100%;
  }
`

const Close = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 0.3s, background-color 0.3s;
  transform: scale(1);
  background-color: white;
  border-color: transparent;
  border-radius: 50%;
  display: block;
  width: 40px;
  height: 40px;

  &:before,
  &:after {
    content: "";
    border-radius: 2px;
    height: 3px;
    width: 24px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.dkGray};
    top: 50%;
    left: 50%;
    margin-top: -1.5px;
    margin-left: -12px;
    display: block;
    transition: background-color 0.3s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
    transform: scale(1.1);
  }

  span {
    ${props => props.theme.mixins.visuallyHidden}
  }
`

const Menu = ({ open, email, phone, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <Root open={open} aria-hidden={!isHidden} {...props}>
      <Close onClick={() => props.setOpen(false)}>
        <span>close</span>
      </Close>
      <AppLogo>
        <Logo />
      </AppLogo>
      <nav>
        <a
          href={`mailto:${email}?subject=Get%20In%20Touch`}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={tabIndex}
        >
          <span>Email Us</span>
        </a>
        {phone && (
          <a
            href={`tel:${phone}`}
            tabIndex={tabIndex}
            rel="noopener noreferrer"
          >
            <span>Call Us</span>
          </a>
        )}
      </nav>
    </Root>
  )
}

export default Menu
